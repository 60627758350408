<template>
  <!-- start rsvp-section -->
  <div class="page-wrapper">
    <section class="rsvp-section section-padding alltheway" id="rsvp">
      <div class="container">
        <div class="row">
          <div class="col col-xs-12">
            <div class="rspv-form ">
              <h3>Da li dolazite?</h3>
              <p>Molimo vas potvrite svoj dolazak do 15. aprila 2022.</p>
              <div class="left-flower"></div>
              <div class="right-flower"></div>
              <form
                id="rsvp-form"
                class="clearfix form validate-rsvp-form row"
                @submit.prevent=""
              >
                <input
                  type="hidden"
                  id="guestId"
                  name="guestId"
                  v-model="selectedGuest.id"
                  v-if="Object.keys(selectedGuest).length === 0"
                />

                <DropdownList @on-item-selected="nameSelected" @on-item-reset="resetEvt" v-if="mountChildren" :initial-state="selectedGuest"/>
<!--                <div-->
<!--                  class="dropdown-selected"-->
<!--                  v-if="Object.keys(selectedGuest).length !== 0"-->
<!--                >-->
<!--                  {{ selectedGuest.firstName + " " + selectedGuest.lastName}}-->
<!--                </div>-->
                <div v-if="Object.keys(selectedGuest).length !== 0">
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    placeholder="Email"
                    v-model="selectedGuest.email"
                  />
                </div>
                <div v-if="Object.keys(selectedGuest).length !== 0">
                  <input
                    type="phone"
                    name="phone"
                    class="form-control"
                    placeholder="Broj telefona"
                    v-model="selectedGuest.phone"
                  />
                </div>
                <div v-if="Object.keys(selectedGuest).length !== 0">
                  <input
                    type="checkbox"
                    id="veganFood"
                    name="veganFood"
                    v-model="selectedGuest.veganFood"
                  />
                  <label for="veganFood">Veganski meni</label>
                </div>
                <div v-if="Object.keys(selectedGuest).length !== 0">
                  <input
                    type="checkbox"
                    id="vegetarianFood"
                    name="vegetarianFood"
                    v-model="selectedGuest.vegetarianFood"
                  />
                  <label for="vegetarianFood">Vegetarijanski meni</label>
                </div>

                <div v-if="Object.keys(selectedGuest).length !== 0">
                  <textarea
                    class="form-control"
                    name="notes"
                    placeholder="Vaša poruka"
                    v-model="selectedGuest.note"
                  ></textarea>
                </div>
                <div class="submit-btn" v-if="Object.keys(selectedGuest).length !== 0">
                  <button type="submit" :class="this.selectedGuest.rsvp === 'YES' ? 'theme-btn-disabled' : 'theme-btn'" @click="rsvpYes()" :disabled="this.selectedGuest.rsvp == 'YES'">
                    Dolazim
                  </button>

                  <button type="submit" :class="this.selectedGuest.rsvp === 'NO' ? 'theme-btn-disabled' : 'theme-btn'" @click="rsvpNo()" :disabled="this.selectedGuest.rsvp == 'NO'">
                    Ne dolazim
                  </button>
                  <div id="loader">
                    <i class="ti-reload"></i>
                  </div>
                </div>
                <div class="success-error-message">
                  <div id="success" v-if="this.success">Hvala na odgovoru!</div>
                  <div id="error" v-if="this.failure">
                    Došlo je do greške, molimo Vas pokušajte ponovo
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- end container -->
    </section>
  </div>
  <!-- end rsvp-section -->
</template>

<script>
import api from "../helpers/axios";
import DropdownList from "@/components/DropdownList";

export default {
  name: "RSVP",
  components: { DropdownList },


  data() {
    return {
      guestList: [],
      inputValue: "",
      success: false,
      failure: false,
      selectedGuest: {},
      dropdownSelection: null,
      suggestionsUrl: "/findGuests/",
      rsvpOptionsUrl: "/rsvpOptions",
      guestUrl: "/guests/",
      mountChildren: false
    };
  },

  mounted() {
    this.initializeGuest();
  },


  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

    rsvpYes() {
      this.selectedGuest.rsvp = "YES";
      this.submitRSVP();
    },

    rsvpNo() {
      this.selectedGuest.rsvp = "NO";
      this.submitRSVP();
    },

    initializeGuest() {
      let guestId = localStorage.getItem("srdjanianaGuestId");
      console.log(guestId);
      if (guestId) {
        api.get(this.guestUrl + guestId).then((response) => {
          this.selectedGuest = response.data;
          this.mountChildren = true;
        });
      } else {
        this.selectedGuest = {};
        this.mountChildren = true;
      }
    },

    nameSelected(evt) {
      console.log(evt);
      this.dropdownSelection = evt;
      api.get(this.guestUrl + evt.id).then((response) => {
        this.selectedGuest = response.data;
      });
    },

    updateGuest() {},

    setGuestLocalStorage(id) {
      localStorage.setItem("srdjanianaGuestId", id);
    },

    submitRSVP() {
      api
        .put(this.guestUrl + this.selectedGuest.id, this.selectedGuest)
        .then(async (response) => {
          localStorage.setItem("srdjanianaGuestId", response.data.id);
          this.success = true;
          this.failure = false;
          this.sleep(2000).then(() => {
            this.$router.push("/");
          });
        })
      .catch((error) => {
        console.log(error);
        this.success = false;
        this.failure = true;
      });
    },
    resetEvt() {
      this.selectedGuest = {};
    }
  },


  computed: {},
};
</script>

<style>
@import "../assets/css/themify-icons.css";
@import "../assets/css/flaticon.css";
@import "../assets/css/bootstrap.min.css";
@import "../assets/css/animate.css";
@import "../assets/css/slick.css";
@import "../assets/css/slick-theme.css";
@import "../assets/css/swiper.min.css";
@import "../assets/css/jquery.fancybox.css";
@import "../assets/css/magnific-popup.css";
@import "../assets/css/style.css";

.alltheway {
  min-height: 750px;
}
</style>
