<template>
  <div class="dropdown">
    <input
      class="dropdown-input"
      type="text"
      placeholder="Ime i prezime"
      :value="inputValue"
      v-if="Object.keys(selectedItem).length === 0"
      ref="dropdowninput"
      @input="evt=>inputValue=evt.target.value"
      v-on:keyup="getSuggestions"
    />
    <div v-else @click="resetItem" class="dropdown-selected">
      {{ selectedItem.firstName + " " + selectedItem.lastName }}
    </div>
    <div class="dropdown-list" v-show="inputValue && apiLoaded">
      <div
        v-for="item in itemList"
        :key="item.id"
        class="dropdown-item"
        @click="selectItem(item)"
      >
        {{ item.guestFirstAndLastName }}
      </div>
    </div>
  </div>
</template>

<script>
import api from "../helpers/axios";

export default {
  name: "DropdownList",

  props: ["initialState"],

  data() {
    return {
      itemList: [],
      inputValue: "",
      apiLoaded: false,
      selectedItem: {},
      apiUrl: "/findGuests/",
      guestsApiUrl: "/guests/",
    };
  },
  mounted() {
    this.selectedItem = JSON.parse(JSON.stringify(this.initialState));
    //this.inputValue = this.initState.firstName + " " + this.initState.lastName;
    //console.log(this.initialState);
    //console.log("MOUNTED");
    //this.selectedItem = this.initialState;
    this.itemList = [];
  },
  methods: {
    initializeComponent() {
      //this.selectedItem = JSON.parse(JSON.stringify(this.initialState));
      console.log(this.selectedItem);
    },
    selectItem(theItem) {
      api.get(this.guestsApiUrl + theItem.id).then((response) => {
        this.selectedItem = response.data;
        this.inputValue = "";
        this.$emit("on-item-selected", this.selectedItem);
      });
    },
    resetItem() {
      this.selectedItem = {};
      this.itemList = [];
      this.$nextTick(() => this.$refs.dropdowninput.focus());
      this.$emit("on-item-reset");
    },
    getSuggestions() {
      console.log("EVENT: " + this.inputValue);
      if (this.inputValue.length > 2) {
        api.get(this.apiUrl + this.inputValue).then((response) => {
          this.itemList = response.data;
          this.apiLoaded = true;
        });
      } else {
        this.itemList = [];
      }
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder {
  opacity: 0.7;
}
.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}
.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}
.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}
.dropdown-item:hover {
  background: #edf2f7;
}
.dropdown-item-flag {
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>
